<template>
  <div id="main" class="pa-4" v-resize="onResize">
    <v-overlay :value="OverlayProses">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         <label> Mohon Tunggu</label>
        </v-col>
        <v-col cols="12">
          <v-progress-linear
            color="red darken-4"
            indeterminate
            rounded
            height="12"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-overlay>
    <!-- Alert Data Sudah Terhapus -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      color="error"
      elevation="4"
      class="rounded-lg"
      icon="mdi-alert-outline"
      v-model="AlertTglPayroll"
      transition="slide-x-transition"
    >
      {{pesan}}
    </v-alert>

    <!-- ---------------------------------------------------- -->

    <!-- dialog info etc -->
    <v-dialog
      v-model="dialogInfo"
      max-width="300"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Informasi
        </v-card-title>
        
        <v-card-text>
            <div cols="3" md="3"><label class="mx-2" style="background-color:red;">ass</label></div>
            <div cols="3" md="3"><label class="mx-2" style="background-color:blue;">assx</label></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end of dialog info etc -->

    <!-- <v-container fluid v-show="this.valid == true"> -->
    <!-- card pc tab and other -->

    <v-toolbar
      tile
      outlined
      height="70"
      class="rounded-lg"
      v-show="loading == false && mobile == false"
    >
      <div class="mt-7 ml-n1 mr-3">
        <v-text-field
          label="Dari Tanggal"
          class="rounded-lg"
          color="dark"
          type="date"
          v-model="dariTanggal"
          outlined
          dense
        >
        </v-text-field>
      </div>

      <div class="mt-7">
        <v-text-field
          label="Sampai Tanggal"
          class="rounded-lg"
          color="dark"
          type="date"
          v-model="sampaiTanggal"
          outlined
          dense
        >
        </v-text-field>
      </div>

      <v-spacer></v-spacer>

      <div>
        <v-btn text outlined class="text-capitalize subtitle-1" @click="getpayroll" color="dark">
          <v-icon class="ml-n2 mr-1">mdi-file-table</v-icon>Proses
        </v-btn>
        <v-btn text outlined class="text-capitalize ml-2 subtitle-1" @click="cetakexcel('container','tes')" color="dark">
          <v-icon class="ml-n2 mr-1">mdi-printer</v-icon>Excel
        </v-btn>
        <v-btn text outlined class="text-capitalize ml-2 subtitle-1" @click="cetakpdf" color="dark">
          <v-icon class="ml-n2 mr-1">mdi-printer</v-icon>Cetak
        </v-btn>
        <v-btn text outlined class="text-capitalize ml-2 subtitle-1" v-show="this.payrolls.length > 0" @click="infotable" color="dark">
          <v-icon class="ml-n2 mr-1">mdi-information-variant</v-icon>Info
        </v-btn>
      </div>
    </v-toolbar>
    <!-- end card pc tab -->

    <!-- card mobile -->
    <v-card outlined class="pa-4 rounded-lg elevation-3" v-show="loading == false && mobile == true">
      <v-row dense>
        <v-col cols="12" sm="6" md="4" class="mb-n5">
          <v-text-field
            label="Dari Tanggal"
            class="rounded-lg"
            color="dark"
            type="date"
            v-model="dariTanggal"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="mb-n5">
          <v-text-field
            label="Sampai Tanggal"
            class="rounded-lg"
            color="dark"
            type="date"
            v-model="sampaiTanggal"
            outlined
            dense
          >
          </v-text-field>
        </v-col>  
      </v-row>
      <v-card-actions class="mb-n2">
        <v-btn text outlined class="ml-n2 text-capitalize" @click="getpayroll" color="dark">
          <v-icon class="mr-1">mdi-file-table</v-icon>
          <span class="mr-1">Proses</span>
        </v-btn>
        <v-btn text outlined class="text-capitalize" @click="cetakpdf" color="dark">
          <v-icon class="mr-1">mdi-printer</v-icon>
          <span class="mr-1">Cetak</span>
        </v-btn>
        <v-btn text outlined class="text-capitalize" v-show="this.payrolls.length > 0" @click="infotable" color="dark">
          <v-icon class="mr-1">mdi-information-variant</v-icon>
          <span class="mr-1">Info</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- end card mobile -->
    <div @scroll="this.tes" class="pa-4" id="container" v-show="this.payrolls.length > 0" ref="table">
      <h3 id="TextHeader" class="p-3 text-left"><img id="GambarHeader" ref="ImageRefs" src="../assets/TA.png"></h3>
      <div class="scroller-wrapper">
      <table class="table table-striped table-bordered" id="tableprint">
          <thead>
              <tr>
      <th colspan="3" align="center" style="border-bottom:none"><center>REKAPITULASI ABSENSI</center></th>
      <th rowspan="1" colspan="1" style="text-align:center;padding:8px;">Dibuat</th>
      <th rowspan="1" colspan="1" style="text-align:center;padding:8px;">Diperiksa</th>
      <th rowspan="1" colspan="1" style="text-align:center;padding:8px;">Diketahui</th>
      <th rowspan="1" colspan="1" style="text-align:center;padding:8px;">Disetujui</th>
      </tr>
      <tr>
        <th class="header1" style="text-align:right;padding:8px;border-right:1px;border-top:none;border-bottom:none;"><span v-show="dariTanggal != '' ">{{domDariTanggal}}</span></th>
      <th class="header1" style="text-align:center;padding:8px;border:none;">sd</th>
      <th class="header1" style="text-align:left;padding:8px;border-left:1px;border-top:none;border-bottom:none;"><span v-show="sampaiTanggal != '' ">{{domSampaiTanggal}}</span></th>
      
      <th style="padding:8px;" rowspan="3"></th>
      <th style="padding:8px;" rowspan="3"></th>
      <th style="padding:8px;" rowspan="3"></th>
      <th style="padding:8px;" rowspan="3"></th>
      </tr>
      <tr>
        <th style="padding:8px;border-right:1px;border-top:none;border-bottom:none;"></th>
        <th style="padding:8px;border:none;"></th>
        <th style="padding:8px;border-left:1px;border-top:none;border-bottom:none;"></th>
      </tr>
      <tr>
        <th style="padding:8px;padding:8px;border-right:1px;border-top:none;border-bottom:none;"></th>
        <th style="padding:8px;border:none;"></th>
        <th style="padding:8px;border-left:1px;border-top:none;border-bottom:none;"></th>
      </tr>
      
      <tr>
        <th style="padding:8px;border-right:1px;border-top:none;"></th>
        <th style="padding:8px;border-top:none;border-left:none;border-right:none;"></th>
        <th style="padding:8px;border-left:1px;border-top:none;"></th>
        
        <th style="padding:6px;" >Adi Jadmiko</th>
        <th style="padding:6px;" >Alfian Malik</th>
        <th style="padding:6px;" >Herdaru Purwianto</th>
        <th style="padding:6px;" >Stephen Christianto Gan</th>
      </tr>
          </thead>
      </table>

      <table >
          <thead id="headertbl">
              <tr height="20px">
      <th rowspan="2" style="padding-left:6px;padding-right:6px;">No</th>
      <th rowspan="2" >NRK</th>
      <th rowspan="2" style="width:400px;">Nama</th>
      <th rowspan="2" style="width:400px;text-align:center;">NamaJabatan</th>
      <th colspan="13" style="text-align:center;">Absensi Result</th>
      <th rowspan="2" style="background-color:#d6dce4;">Score</th>
      <th rowspan="2" style="background-color:#d6dce4;" class="notprint">Control</th>
      <th rowspan="2" style="width:200px;background-color:#ffff00;" class="notprint">No Work No Pay</th>
      <th rowspan="2" style="background-color:#ffff00;" class="notprint">FORMULA</th>
      <th colspan="6" style="width:400px" align="center" class="notprint">Keterangan</th>
      </tr>
      <tr height="25px">
        <th style="text-align:center;padding:4px;background-color:#92d050;">H</th>
        <th style="text-align:center;padding:4px;background-color:#ff0000;">A</th>
        <th style="text-align:center;padding:4px;background-color:#548235;">I</th>
        <th style="text-align:center;padding:4px;background-color:#a9d08e;">IR</th>
        <th style="text-align:center;padding:4px;background-color:#acb9ca;">S</th>
        <th style="text-align:center;padding:4px;background-color:#595959;">SD</th>
        <th style="text-align:center;padding:4px;background-color:#ffffff;">1/2</th>
        <th style="text-align:center;padding:4px;background-color:#f4b084;">S/B</th>
        <th style="text-align:center;padding:4px;background-color:#1f4e78;">CT</th>
        <th style="text-align:center;padding:4px;background-color:#00b0f0;">L</th>
        <th style="text-align:center;padding:4px;background-color:#7030a0;">LB</th>
        <th style="text-align:center;padding:4px;background-color:#ffff00;">OFF</th>
        <th style="text-align:center;padding:4px;background-color:#c00000;">UM</th>
      
        <th class="notprint" style="text-align:center;padding:4px;background-color:#ff0000;">A</th>
        <th class="notprint" style="text-align:center;padding:4px;background-color:#548235;">I</th>
        <th class="notprint" style="text-align:center;padding:4px;background-color:#c6e0b4;">IR</th>
        <th class="notprint" style="text-align:center;padding:4px;background-color:#acb9ca;">S</th>
        <th class="notprint" style="text-align:center;padding:4px;background-color:#808080;">SD</th>
        <th class="notprint" style="text-align:center;padding:4px;background-color:#1f4e78;">CT</th>
      </tr>
          </thead>

          <tbody>
              <tr v-for="payroll in payrolls" :key="payroll.id" id="data">
                  <td style="mso-number-format:'0';text-align:center;">{{payroll.No}}</td>
                  <td style="mso-number-format:'0';text-align:center;padding-left:8px;padding-right:8px;">{{payroll.NRK}}</td>
                  <td style="text-align:left;padding-left:8px;padding-right:8px;">{{payroll.Nama}}</td>
                  <td style="text-align:left;padding-left:8px;padding-right:8px;" class='NamaJabatan'>{{payroll.NamaJabatan}}</td>
                  <td class="absensi">{{payroll.Hadir}}</td>
                  <td class="absensi">{{payroll.Alpa}}</td>
                  <td class="absensi">{{payroll.Izin}}</td>
                  <td class="absensi">{{payroll.IzinResmi}}</td>
                  <td class="absensi">{{payroll.Sakit}}</td>
                  <td class="absensi">{{payroll.SakitDokter}}</td>
                  <td class="absensi">{{payroll.SetengahHari}}</td>
                  <td class="absensi">{{payroll.SB}}</td>
                  <td class="absensi">{{payroll.Cuti}}</td>
                  <td class="absensi">{{payroll.Libur}}</td>
                  <td class="absensi">{{payroll.LiburBersama}}</td>
                  <td class="absensi">{{payroll.OffSecurity}}</td>
                  <td class="absensi">{{payroll.UangMakan}}</td>
                  <td class="absensi">{{payroll.Score}}</td>
                  <td class="notprint" id="absensi">{{payroll.Control}}</td>
                  <td class="notprint">{{payroll.NoWorkNoPay}}</td>
                  <td class="notprint">{{payroll.Formula}}</td>
                  <td class="notprint">{{payroll.KeteranganAlpa}}</td>
                  <td class="notprint">{{payroll.KeteranganIzin}}</td>
                  <td class="notprint">{{payroll.KeteranganIzinResmi}}</td>
                  <td class="notprint">{{payroll.KeteranganSakit}}</td>
                  <td class="notprint">{{payroll.KeteranganSakitDokter}}</td>
                  <td class="notprint">{{payroll.KeteranganCuti}}</td>
              </tr>
          </tbody>
      </table>
      </div>
    </div>
  <!-- </v-container> -->
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
// import html2PDF from 'jspdf-html2canvas';
// import html2canvas from 'html2canvas';
// import html2pdfjs from 'html2pdf.js'
// import jsPDF from "jspdf";
// import 'jspdf-autotable'
Vue.use(GridPlugin);

export default {
  data() {
    return {
      mobile:null,
      pesan:'',
      dariTanggal:'',
      domDariTanggal:'',
      sampaiTanggal:'',
      domSampaiTanggal:'',
      dialogInfo:false,
      payrolls: [
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Reski Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
                // { No: '1', NRK: '111111112222', Nama: 'Akhir Widhianto', NamaJabatan: 'Head of First General Division', Hadir:'26', Alpa:'0', Izin:'0', IzinResmi:'0', Sakit:'0', SakitDokter:'0', SetengahHari:'0', KosongSaja:'0', Cuti:'0', Libur:'1', LiburBersama:'0', OffSecurity:'4', UangMakan:'27', Score:'31', Control:'0', NoWorkNoPay:'-' },
            ],
      valid:null,
      textAktif:true,
      umenu:[],
      OverlayProses:false,
      templatepayroll:false,
      loading:true,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [
        {
          buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons"}
        },
        {
          buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons"}
        }
      ],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      AlertTglPayroll:false,
      AlertBerhasilTerubah:false,
      user:[],
      token:null,
      form: new FormData,
      editedIndex: -1,
      toolbarOptions: ['Search'],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

 async mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    await Promise.all([this.saya()])
    this.getdata()
  },

  computed: {
    formTitlePerusahaan() {
      return this.editedIndex === -1 ? 'Tambah Data Perusahaan' : 'Ubah Data Perusahaan'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
    dariTanggal(){
      var d = new Date(this.dariTanggal)
      const bulan = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      this.domDariTanggal = ('0' + d.getDate()).slice(-2) + '-' + bulan[d.getMonth()] + '-' + d.getFullYear().toString().substr(-2)
      // alert(this.dariTanggal)
      // if (this.dariTanggal && this.sampaiTanggal != '') {
      //   this.getpayroll()
      // }
    },
    sampaiTanggal(){
      var d = new Date(this.sampaiTanggal)
      const bulan = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      this.domSampaiTanggal = ('0' + d.getDate()).slice(-2) + '-' + bulan[d.getMonth()] + '-' + d.getFullYear().toString().substr(-2)
      // alert(this.dariTanggal)
      // if (this.dariTanggal && this.sampaiTanggal != '') {
      //   this.getpayroll()
      // }
    },
  
      // let a = this.DataPerusahaan.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 800) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahPerusahaan (val) {
      val || this.KeluarDialogPerusahaan()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
    AlertTglPayroll(){
      this.AlertItem()
    }
  },

  methods: {
    tes(){
      console.log('ok')
      var kontainer = document.getElementsByClassName("container")
      var header = document.getElementById("headertbl");
      var sticky = header.offsetTop;
      if (kontainer.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    },
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        
      // },

    Simpan(){
      if(this.formTitlePerusahaan === "Tambah Data Perusahaan"){
          api.post('/perusahaan?token='+this.token,{
            KodePerusahaan: this.editedItem.KodePerusahaan,
            NamaPerusahaan: this.editedItem.NamaPerusahaan,
            Alamat: this.editedItem.Alamat,
            },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
            .then((res)=>{
              if (res) {
                this.Alert = true
                this.getdata()
              } else {
                //
              }
            })
      }else if(this.formTitlePerusahaan === "Ubah Data Perusahaan"){
         api.put("/perusahaan/"+this.KodePerusahaan+'/?token='+this.token,{
            KodePerusahaan: this.editedItem.KodePerusahaan,
            NamaPerusahaan: this.editedItem.NamaPerusahaan,
            Alamat: this.editedItem.Alamat,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.KeluarDialogPerusahaan()    
    },

    PdfExport(){
      let data = document.getElementById('perusahaanview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportPerusahaan.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridperusahaan.columns[0].visible = false;
      // this.$refs.gridperusahaan.columns[1].visible = true;
      this.$refs.gridperusahaan.pdfExport(data.pdfExportProperties);
    },
    infotable(){
      this.dialogInfo = true
    },

    getpayroll(){
      // var c = document.createElement('canvas');
      // var img = document.getElementById('GambarHeader');
      // c.height = img.naturalHeight;
      // c.width = img.naturalWidth;
      // var ctx = c.getContext('2d');
      // ctx.drawImage(img, 0, 0, c.width, c.height);
      // this.$refs.ImageRefs.src = c.toDataURL();
      // if (this.valid == true) {
        if (this.dariTanggal && this.sampaiTanggal != '') {
          this.OverlayProses = true
          api.get('/absenoutput/'+this.dariTanggal+'/'+this.sampaiTanggal+'?token='+this.token).then(res=>{
            console.log('res',res)
          if (res) {
            for (let index = 0; index < res.data.Absensi.length; index++) {
              const element = res.data.Absensi[index];
              element.Alpa = (element.Alpa == 0) ? '-' : element.Alpa;
              element.Izin = (element.Izin == 0) ? '-' : element.Izin;
              element.IzinResmi = (element.IzinResmi == 0) ? '-' : element.IzinResmi;
              element.Sakit = (element.Sakit == 0) ? '-' : element.Sakit;
              element.SakitDokter = (element.SakitDokter == 0) ? '-' : element.SakitDokter;
              element.SetengahHari = (element.SetengahHari == 0) ? '-' : element.SetengahHari;
              element.SB = (element.SB == 0) ? '-' : element.SB;
              element.Cuti = (element.Cuti == 0) ? '-' : element.Cuti;
              element.Libur = (element.Libur == 0) ? '-' : element.Libur;
              element.LiburBersama = (element.LiburBersama == 0) ? '-' : element.LiburBersama;
              element.OffSecurity = (element.OffSecurity == 0) ? '-' : element.OffSecurity;
              element.UangMakan = (element.UangMakan == 0) ? '-' : element.UangMakan;
              element.Score = (element.Score == 0) ? '-' : element.Score;
            }
            this.OverlayProses = false
            this.payrolls = res.data.Absensi
            }
         })
        }else{
          this.AlertTglPayroll = true
          this.OverlayProses = false
          this.pesan = "Pastikan seluruh tanggal terisi"
        }
        
      // }
    },

    getdata(){
      api.get('/perusahaan?token='+this.token,
      // { headers: {"Authorization" : `Bearer ${this.token}`} }
      ).then(
				res => {
					// console.log(res);
          if (res) {
            this.DataPerusahaan = res.data
          this.loading = false
          }
          
				},
				err => {
					console.log(err);
				}
			)
    },
    cetakexcel(){
      // var tabel = 'container'
      // var nama = 'okdonlod'
      // var tableToExcel = (function () {
      //   var uri = 'data:application/vnd.ms-excel',
      //     template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
      //     base64 = function (s) {
      //       return window.btoa(unescape(encodeURIComponent(s)))
      //     },
      //     format = function (s, c) {
      //       return s.replace(/{(\w+)}/g, function (m, p) {
      //         return c[p];
      //       })
      //     }
      //   return function (table, name) {
      //     if (!table.nodeType) table = document.getElementById(table)
      //     var ctx = {
      //       worksheet: name || 'Worksheet',
      //       table: table.innerHTML
      //     }
      //     window.location.href = uri + base64(format(template, ctx))
      //   }
      // })()
      // tableToExcel(tabel,nama)
      // document.getElementById("GambarHeader").style.display = "none";
      var header = document.getElementById('GambarHeader')
      var saved = header.cloneNode(true)
      header.remove()
      var tableId = 'container'
      var filename = 'Export '+this.dariTanggal+'-'+this.sampaiTanggal
          let dataType = 'application/vnd.ms-excel';
          let extension = '.xls';

          let base64 = function(s) {
              return window.btoa(unescape(encodeURIComponent(s)))
          };
          let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><img src="{imgsrc1}" style="float:left;clear:none;margin-right:50px " height=100 width=200/><table>{table}</table></body></html>';
          let render = function(template, content) {
              return template.replace(/{(\w+)}/g, function(m, p) { return content[p]; });
          };
          // console.log(render)
          let tableElement = document.getElementById(tableId);

          let tableExcel = render(template, {
              imgsrc1 : this.$refs.ImageRefs.src,
              worksheet: filename,
              table: tableElement.innerHTML
          });

          filename = filename + extension;

          if (navigator.msSaveOrOpenBlob)
          {
              let blob = new Blob(
                  [ '\ufeff', tableExcel ],
                  { type: dataType }
              );

              navigator.msSaveOrOpenBlob(blob, filename);
          } else {
              let downloadLink = document.createElement("a");

              document.body.appendChild(downloadLink);

              downloadLink.href = 'data:' + dataType + ';base64,' + base64(tableExcel);

              downloadLink.download = filename;

              downloadLink.click();
          }
          // console.log(saved)
          document.getElementById('TextHeader').appendChild(saved)
          // document.getElementById("GambarHeader").style.removeProperty('display');
    },
    cetakpdf(){
      let contain = document.getElementById('container')
      contain.style.height = 'auto'
      let tes = document.getElementsByTagName('tr')
      console.log(tes.length)
      // document.querySelectorAll('[id=notprint]').style.display = "none";
      // document.getElementsByClassName("notprint").style.display = "none";
      for (const elem of document.querySelectorAll('.notprint')) {
          elem.style.display = 'none';
      }
      // let html = this.$refs.table
      // var opt = {
      //     margin:       1,
      //     filename:     'myfile.pdf',
      //     image:        { type: 'jpeg', quality: 0.98 },
      //     html2canvas:  { scale: 2 },
      //     jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      //   };
      // var originalContents = document.body.innerHTML;
      // var printReport= contain.innerHTML;
      // document.body.innerHTML = printReport;
      // window.print();
      // document.body.innerHTML = originalContents;
        const prtHtml = contain.innerHTML;
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }

        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var is_chrome = Boolean(WinPrint.chrome);
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);
        WinPrint.document.close();
        if (is_chrome) {
        WinPrint.onload = function() { // wait until all resources loaded 
            WinPrint.focus(); // necessary for IE >= 10
            WinPrint.print();  // change window to mywindow
            WinPrint.close();// change window to mywindow
            };
        }
        else {
            WinPrint.document.close(); // necessary for IE >= 10
            WinPrint.focus(); // necessary for IE >= 10
            WinPrint.print();
            WinPrint.close();
        }

        // WinPrint.document.close();
        // WinPrint.focus();
        // WinPrint.print();
        // WinPrint.close();
        // html2canvas(contain).then(canvas => {
          
        //   let imgFile = canvas.toDataURL("image/jpeg", 0.3);
        //   var doc = new jsPDF('p', 'px', 'a4', true);
        //   let width = doc.internal.pageSize.getWidth()
        //   let height = doc.internal.pageSize.getHeight()
        //   let widthRatio = width / canvas.width
        //   let heightRatio = height / canvas.height
        //   let ratio = widthRatio > heightRatio ? heightRatio : widthRatio
        //   doc.addImage(imgFile, "JPEG", 0,  0, width , canvas.height * ratio);
        //   doc.save('Test.pdf');
        // });
            
      //   // New Promise-based usage:
      //   html2pdfjs().set(opt).from(contain).output();
      // contain.scrollTo(0,0)
      // contain.scrollTo(0, contain.scrollHeight || contain.documentElement.scrollHeight);
      //     html2PDF(contain).then(canvas => {
      //     const imgData = canvas.toDataURL('image/png');
      //     const pdf = new jsPDF('l', 'px', 'a4',true);
      //     const width = pdf.internal.pageSize.getWidth();
      //     const height = pdf.internal.pageSize.getHeight();

      //     pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      //     pdf.save('')
      // });
      // html2PDF(contain, {
      //     jsPDF: {
      //       format: 'a4',
      //       orientation: "p",
      //       unit: "px"
      //     },
      //     output: this.token+'.pdf',
      //     init: function () {
      //     },
      //     success: function () {
      //       // console.log(pdf)
      //       // pdf.output(this.output)
      //       // console.log(pdf)
      //       // pdf.save(this.output);
      //     }
      //   })
      // html2PDF(html, {
      //     jsPDF: {
      //       format: 'a4',
      //       orientation: "l",
      //       unit: "px"
      //     },
      //     output: this.token+'.pdf',
      //     init: function (init) {
      //       console.log(init)
      //     },
      //     success: function (pdf) {
      //       console.log(pdf)
      //       // pdf.output(this.output)
      //       pdf.save(this.output);
      //     }
      //   })
      // html2PDF(html,{scrollY: -window.scrollY}).then(function(canvas) {
      //       var img = canvas.toDataURL();
      //       window.open(img);
      //   });
      
      // console.log(html)
      // console.log(doc)
      // doc.html(html,{
      //   callback: (pdf) => {
      //     pdf.save('web.pdf');
      //   },
      //   background: '#000',
      //   format: 'PNG',
      //   pagesplit: true
      //   // callback:function(pdf){
      //   //   var hitungpage = doc.internal.getNumberOfPages()
      //   //   pdf.deletePage(hitungpage)
      //   //   pdf.save('awokwk.pdf')
      //   // }
      // });
      // doc.save('tesawokwsk.pdf')
      // pdf.output("dataurlnewwindow");
      // pdf.addHTML( html , function() {
      //     pdf.save('filename.pdf');
      // });
      // this.templatepayroll = false
      contain.style.height = '700px'
      for (const elem of document.querySelectorAll('.notprint')) {
          elem.style.removeProperty('display')
      }
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertTglPayroll = false
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style>
#container{
height: 550px; 
width:100%; 
padding:4px;
/* width:587px; */
/* height:834px; */
overflow: scroll;
white-space:nowrap;   
}
.scroller{
  height: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}
table {
  border-collapse:collapse;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  page-break-inside:auto
}
th,td {
	border: 1px solid #000000;
  page-break-inside:avoid; page-break-after:auto
}
tr{
  page-break-inside:avoid; page-break-after:auto
}
/* td.NamaJabatan { font-size: 13px; } */
.absensi {
  text-align:right;
  padding-left:8px;
  padding-right:8px;
}
#absensi{
  text-align:right;
  padding-left:8px;
  padding-right:8px;
}
/* .html2canvas-container { 
    width: 3000px; 
    height: 3000px; 
} */
</style>

